<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Cadastro de Metas Promotor</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 
            <template v-slot:top>
              <BrToolBar
                @search="buscar"
                :configFilter="{ listaSelect: itemSelectKeys, getItens: getItens, jsonData: true }" :addlFilter="true"
                :labelTextField="'Busca por Código Promotor:'" />
            </template>

            <template v-slot:item.entityName="{ item }">
               {{ item.entities.jsonData.cod }} - {{ item.entities.jsonData.name }}
            </template>

            <template v-slot:item.Goals.refDate="{ item }">
               {{ item.Goals.refDate | datas('DD/MM/YYYY')}}
            </template>
            <template v-slot:item.salesGoalValue="{ item }">
               {{ item.salesGoalValue | currency }}
            </template>
            <template v-slot:item.debitGoalValue="{ item }">
               {{ item.debitGoalValue | percentage }}
            </template>

          
             <template v-slot:item.status="{ item }">
              <v-chip style="cursor:pointe" @click="updateStatusAproved(item)" v-if="permiteAcao($route, 'edit')"  :color="getColorSituacao(item.status)" dark>{{item.status === 'P' ? 'Pendente': 'Aprovado' }}</v-chip>
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn
                v-if="permiteAcao($route, 'edit')"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Editar
              </v-btn>

              <v-btn
                v-if="permiteAcao($route, 'delete') && permiteAcao({path:'/cadastros/metas/metas-entidades/status'}, 'edit')"
                class="mr-2"
                small
                @click="deleteItem(item)"
                tile
                outlined 
                :color="variables.colorError"
              >
                Excluir
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>

        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar } from '@/core/service/utils'

import listagemTables from '@/views/mixins/listagemTables'
export default {
  name: 'CadastroMetasEntidades',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    activeModal: false,
    headers: [
      // {align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      {align: 'start', class: 'table-header', text: 'Promotor', value: 'entityName' },
      {align: 'start', class: 'table-header', text: 'Data de Referência', value: 'Goals.refDate' },
      {align: 'end', class: 'table-header', text: 'Meta de Vendas', value: 'salesGoalValue' },
      {align: 'end', class: 'table-header', text: 'Meta de Débito', value: 'debitGoalValue' },
      {align: 'end', class: 'table-header', text: 'Qtd. Revendedores', value: 'resellersQuantityGoal' },
      {align: 'center', class: 'table-header', text: 'Status', value: 'status' },
      {align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),
  computed: {
    ...mapGetters('metasEntidades', ['listaItens', 'totalItens', 'item']),
    ...mapGetters('roles', ['permiteAcao']),
    itemSelectKeys () {
      return {
        id: '',
        Entities: {
          cod: '',
          name: '',
        },
        Goals: {
          refDate: ''
        },
        salesGoalValue: '',
        debitGoalValue: '',
        resellersQuantityGoal: '',
        EntityGoals: {
          status: ''
        }
      }
    }
  },

  mounted () {
    // this.obterItensSelect()
  },

  methods: {
    ...mapActions('metasEntidades', ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens', 'approvedStatus']),
    updateStatusAproved(v) {

      // verifica se a rota meta status é abilitada
      if (!this.permiteAcao({path:'/cadastros/metas/metas-entidades/status'}, 'edit')) return false
      if (v.status === 'V') return false

      let dados = Object.assign({}, v)
      this.loadingTable = true

      this.approvedStatus({id: dados.id}).then(() => {
        this.msgAll('Status alterado com sucesso!')
        this.getItens().then(() => {

        }).finally(() => this.loadingTable = false )
      }).catch(() => {
        this.msgAll( 'Meta não encontrada!',true)
        this.loadingTable = false
      })
    },
    getColorSituacao(v) {
      if(v === 'P') return 'orange'
      return 'green'
    },

    buscar(val) {
      this.loadingPag = true
      this.objCampoBusca['_addlFilter'] = { "Entity:cod_ilike":`${val}%` }
      this
        .getItens({ _addlFilter: { "Entities:cod_ilike":`${val}%` } })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => { this.loadingPag = false })
    },
  }
}
</script>

<style lang="scss">
  @import './../../../../assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
